<template>
    <v-content>
        <v-container fluid>
            <!-- <v-row no-gutters>
                 <v-col cols="10"></v-col>
                 <v-col cols="2">
                     <v-btn cols="2" class="success" @click.stop="onDownload">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-col>
             </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <div>
                                <v-row align="start" justify="start">

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.ReportType"
                                                        :items="ReportTypes"
                                                        :label="$t('report_type')"
                                                        clearable
                                                        dense
                                                        outlined
                                                        single-line
                                                        item-text="id"
                                                        item-value="value"></v-autocomplete>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-select :items="customYear"
                                                  dense
                                                  outlined
                                                  :label="$t('select_year')"
                                                  v-model="serverParams.FromDate"
                                                  prepend-inner-icon="event"></v-select>
                                    </v-col>

                                    <!--   <v-col class="d-flex" cols="12" sm="3" v-if="!reportIsMonthWise">
                                           <v-menu
                                                   v-model="snackbar.menu2"

                                                   :nudge-right="40"
                                                   transition="scale-transition"
                                                   offset-y
                                                   min-width="290px"
                                           >
                                               <template v-slot:activator="{ on }">
                                                   <v-text-field
                                                           v-model="serverParams.ToDate"
                                                           label="To"
                                                           clearable
                                                           prepend-icon="event"
                                                           readonly
                                                           v-on="on"
                                                   ></v-text-field>
                                               </template>
                                               <v-date-picker v-model="serverParams.ToDate">

                                               </v-date-picker>
                                           </v-menu>
                                       </v-col>-->

                                    <v-col class="d-flex flex-sm-row flex-column" cols="12" sm="3">

                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>

                                        <download-excel :fields="excelData.json_fields"
                                                        :fetch="onDownload"
                                                        worksheet="My Worksheet">
                                            <v-btn small class="success mx-4">
                                                <v-icon>get_app</v-icon>
                                                {{$t('download_to_excel')}}
                                            </v-btn>
                                        </download-excel>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import downloadExcel from "vue-json-excel";
    export default {
        name: "MountaineeringReport",
        props: [
            'moduleName'
        ],
        components: { downloadExcel },
        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            formResponse(data) {
                console.log({ data })
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            async onDownload() {
                this.excelData = {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                }
                let param = {
                    //ToDate: this.serverParams.ToDate,
                    FromDate: this.serverParams.FromDate,
                };

                if (this.serverParams.ReportType == "genderVsPeakWise") {
                    await axios({
                        url: 'Mountaining/DownloadGenderVsPeakWise',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'MountainClimberGenderByPeak.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                else if (this.serverParams.ReportType == "genderVsCountryWise") {
                    await axios({
                        url: 'Mountaining/DownloadGenderVsCountryWise',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'MountainClimberGenderVsCountryWise.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                else if (this.serverParams.ReportType == "countryVsMountainWise") {
                    await axios({
                        url: 'Mountaining/DownloadCountryVsMountainWise',
                        method: 'POST',
                        data: param
                    }).then((response) => {
                        this.excelData.json_fields['Mountain'] = 'Mountain'
                        response.data.header.map(res => {
                            this.excelData.json_fields[res.label] = res.field
                        })
                        this.excelData.json_fields['Total'] = 'Total'
                        this.excelData.json_data = response.data.dataList
                    });
                    return this.excelData.json_data
                }
                else {
                    await axios({
                        url: 'Mountaining/DownloadClimberbypeakAsync',
                        method: 'POST',
                        data: param
                    }).then((response) => {

                        this.excelData.json_fields['Country'] = 'Country'
                        response.data.header.map(res => {
                            this.excelData.json_fields[res.label] = res.field
                        })
                        this.excelData.json_fields['Total'] = 'Total'
                        this.excelData.json_data = response.data.dataList
                    });
                    return this.excelData.json_data

                }
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'NationalParkFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
            },
            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let url = ''
                let param = {
                    FromDate: this.serverParams.FromDate + '-01-01',
                };
                if (this.reportIsGenderVsCountryWise) {
                    url = 'Mountaining/GetMountainClimberGenderByCountryAsync'
                    this.assignColumn()
                } else if (this.reportIsGenderVsPeakWise) {
                    url = 'Mountaining/GetMountainClimberGenderByPeakAsync'
                    this.assignColumn()
                } else if (this.reportIsCountryVsMountainWise) {
                    url = 'Mountaining/GetMountainClimberByCountryAndMountainAsync'
                } else {
                    url = 'Mountaining/GetMountainClimberbypeakAsync'
                }
                axios.post(url, param)
                    .then(response => {
                        if (this.reportIsCountryVsMountainWise) {
                            console.log('response.data', response.data)
                            this.rows = response.data.dataList
                            this.columns = []
                            this.columns = response.data.header
                            this.columns.unshift(
                                { label: 'Mountain', field: 'Mountain' }
                            )
                            this.columns.push(
                                { label: 'Total', field: 'Total' }
                            )
                        } else if (this.reportIsPeakWise) {
                            this.rows = response.data.dataList
                            this.columns = []
                            this.columns = response.data.header
                            this.columns.unshift(
                                { label: 'Country', field: 'Country' }
                            )
                            this.columns.push(
                                { label: 'Total', field: 'Total' }
                            )
                        } else {
                            this.rows = response.data
                            this.snackbar.loading = false;
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage == -1 ? this.totalRecords : params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            assignColumn() {
                if (this.reportIsGenderVsPeakWise) {
                    this.columns = []
                    this.columns.push(
                        {
                            label: 'peak',
                            field: 'mountain'
                        },
                        {
                            label: 'male',
                            field: 'permitMale'
                        },
                        {
                            label: 'female',
                            field: 'permitFemale'
                        },

                        {
                            label: 'permit_total',
                            field: 'permitTotal'
                        },
                        {
                            label: 'male',
                            field: 'summiteMale'
                        },
                        {
                            label: 'female',
                            field: 'summiteFemale'
                        },

                        {
                            label: 'summit_total',
                            field: 'summiteTotal'
                        },
                    )
                } else if (this.reportIsGenderVsCountryWise) {
                    this.columns = []
                    this.columns.push(
                        {
                            label: 'country',
                            field: 'country'
                        },
                        {
                            label: 'male',
                            field: 'permitMale'
                        },
                        {
                            label: 'female',
                            field: 'permitFemale'
                        },

                        {
                            label: 'permit_total',
                            field: 'permitTotal'
                        },
                        {
                            label: 'male',
                            field: 'summiteMale'
                        },
                        {
                            label: 'female',
                            field: 'summiteFemale'
                        },

                        {
                            label: 'summit_total',
                            field: 'summiteTotal'
                        },
                    )
                }
            }
        },
        mounted() {
            let questionNepali = localStorage.getItem('lang');
            this.ReportTypes = [];
            if (questionNepali == 'ne') {

                this.ReportTypes = this.report.filter(function (data) {
                    return data.IsNp === true;
                });
            }
            else {
                this.ReportTypes = this.report.filter(function (data) {
                    return data.IsNp === false;
                });
            }
            this.getNow()
            //this.loadItems()
        },
        data() {
            return {
                excelData: {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                },
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                columns: [],
                rows: [],
                report: [
                    {
                        id: "Gender vs Peak", value: "genderVsPeakWise", IsNp: false
                    },
                    {
                        id: "लिंग vs शिखर", value: "genderVsPeakWise", IsNp: true
                    },
                    {
                        id: "Gender vs Country", value: "genderVsCountryWise", IsNp: false
                    },
                    {
                        id: "लिंग vs देश", value: "genderVsCountryWise", IsNp: true
                    },
                    {
                        id: "Country Vs Mountain", value: "countryVsMountainWise", IsNp: false
                    },
                    {
                        id: "देश Vs पर्वत", value: "countryVsMountainWise", IsNp: true
                    },
                    {
                        id: "Peak Wise", value: "peakWise", IsNp: false
                    },
                    {
                        id: "शिखर", value: "peakWise", IsNp: true
                    },
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,

                }
            };
        },
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            reportIsGenderVsPeakWise() {
                return this.serverParams.ReportType === "genderVsPeakWise";
            },
            reportIsGenderVsCountryWise() {
                return this.serverParams.ReportType === "genderVsCountryWise";
            },
            reportIsCountryVsMountainWise() {
                return this.serverParams.ReportType === "countryVsMountainWise";
            },
            reportIsPeakWise() {
                return this.serverParams.ReportType === "peakWise";
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('modules_name.mountaineering'),
                        disabled: true,
                        exact: true
                    }
                ]
            }

        },
    }
</script>

<style scoped>
</style>
